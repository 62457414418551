import http from "./http-common-reporting";
import { v4 as uuidv4 } from "uuid";

let visitorId;

class ReportVisitService {
  report(path, title) {
    if (!visitorId) {
      visitorId = uuidv4();
    }

    const data = {
      id: "bb9b1812-dc9d-467c-bed8-c601717dd62b",
      path: path,
      pageName: title,
      visitorId: visitorId,
    };
    http.post("/", data);
  }
}

export default new ReportVisitService();
