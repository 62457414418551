import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Authentication from "./components/authentication.component";
import ResetPassword from "./components/reset-password.componet";

import imgIconHeader from "./icons/icon_header.png";

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <header>
          <div>
            <div className="title">
              <img
                src={imgIconHeader}
                alt="Authentication"
                height="40"
                width="40"
              />
              <h1>Authentication</h1>
            </div>
          </div>
        </header>

        <Routes>
          <Route exact path="/" element={<Authentication />} />
          <Route exact path="/resetPassword" element={<ResetPassword />} />
        </Routes>

        <footer>
          <a href="https://www.davidsimak.dev">Design & Built by David Šimák</a>
        </footer>
      </div>
    );
  }
}

export default App;
