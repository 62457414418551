import React, { Component } from "react";
import CookieHelper from "../CookieHelper";
import methods from "../methods";
import reportVisitService from "../reporting-visits/report-visit.service";
import authenticationService from "../services/authentication.service";

export default class Authentication extends Component {
  constructor(props) {
    super(props);
    this.signIn = this.signIn.bind(this);
    this.signUp = this.signUp.bind(this);
    this.checkTermsOfServices = this.checkTermsOfServices.bind(this);
    this.changeSignMethod = this.changeSignMethod.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);

    this.state = {
      isRegistering: false,
      email: "",
      password: "",
      confirmPassword: "",
      checkedTermsOfService: false,
      errorMessage: "",
      isLoading: false,
    };
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  signIn() {
    if (
      this.state.email.length > 0 &&
      methods.validateEmail(this.state.email)
    ) {
      if (this.state.password.length > 0) {
        this.setState({
          isLoading: true,
        });

        authenticationService
          .login({
            email: this.state.email,
            password: this.state.password,
          })
          .then((response) => {
            if (response.data.status === "SUCCESS") {
              CookieHelper.create(response.data.session);
              let params = window.location.search;
              if (params.includes("?appName")) {
                window.location.href =
                  "https://" +
                  params.replace("?appName=", "") +
                  ".davidsimak.dev/";
              } else {
                window.location.href = "https://account.davidsimak.dev";
              }
            } else {
              this.setState({
                isLoading: false,
                errorMessage: response.data.message,
              });
            }
          })
          .catch((e) => {
            methods.processError(e);
          });
      } else {
        this.setState({
          errorMessage: "For sign in to app, you must enter password!",
        });
      }
    } else {
      this.setState({
        errorMessage: "For sign in to app, you must enter valid email!",
      });
    }
  }

  signUp() {
    if (
      this.state.email.length > 0 &&
      methods.validateEmail(this.state.email)
    ) {
      if (this.state.password.length > 0) {
        if (this.state.confirmPassword.length > 0) {
          if (this.state.confirmPassword === this.state.password) {
            this.setState({
              isLoading: true,
            });
            authenticationService
              .register({
                email: this.state.email,
                password: this.state.password,
              })
              .then((response) => {
                if (response.data.status === "SUCCESS") {
                  CookieHelper.create(response.data.session);
                  let params = window.location.search;
                  if (params.includes("?appName")) {
                    window.location.href =
                      "https://" +
                      params.replace("?appName=", "") +
                      ".davidsimak.dev/";
                  } else {
                    window.location.href = "https://account.davidsimak.dev";
                  }
                } else {
                  this.setState({
                    isLoading: false,
                    errorMessage: response.data.message,
                  });
                }
              })
              .catch((e) => {
                methods.processError(e);
              });
          } else {
            this.setState({
              errorMessage:
                "For sign up to app, you must enter confirm password!",
            });
          }
        } else {
          this.setState({
            errorMessage:
              "For sign up to app, you must enter confirm password!",
          });
        }
      } else {
        this.setState({
          errorMessage: "For sign up to app, you must enter password!",
        });
      }
    } else {
      this.setState({
        errorMessage: "For sign up to app, you must enter valid email!",
      });
    }
  }

  onChangeConfirmPassword(e) {
    this.setState({
      confirmPassword: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  changeSignMethod = () => {
    this.setState({
      isRegistering: !this.state.isRegistering,
    });
  };

  checkTermsOfServices = () => {
    this.setState({
      checkedTermsOfService: !this.state.checkedTermsOfService,
    });
  };

  componentDidMount() {
    document.title =
      "Authentication | Authenticate to ecosystem of apps created by David Šimák";

    reportVisitService.report(window.location.pathname, document.title);

    if (CookieHelper.isInBrowser()) {
      window.location.href = "https://account.davidsimak.dev/#/";
    }
  }

  render() {
    return (
      <div>
        {this.state.isLoading ? <div id="loader" /> : null}

        <div className="container mt-3">
          {this.state.isRegistering ? (
            <div className="auth_form">
              <label className="title_label">Sign Up</label>
              <br />
              <label className="label">E-mail</label>
              <br />
              <input
                className="input_box"
                type="email"
                value={this.state.email}
                onChange={this.onChangeEmail}
              />
              <br />
              <label className="label">Password</label>
              <br />
              <input
                type="password"
                id="password"
                className="input_box"
                value={this.state.password}
                onChange={this.onChangePassword}
              />
              <br />
              <label className="label">Confirm Password</label>
              <br />
              <input
                type="password"
                id="confirm_password"
                className="input_box"
                value={this.state.confirmPassword}
                onChange={this.onChangeConfirmPassword}
              />
              <br />
              <br />
              <button
                className={
                  this.state.checkedTermsOfService
                    ? "btn_checked"
                    : "btn_unchecked"
                }
                onClick={this.checkTermsOfServices}
              />{" "}
              &nbsp;&nbsp; Agree to{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.davidsimak.dev/privacy-policy/"
              >
                Privacy Policy
              </a>{" "}
              and{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.davidsimak.dev/terms-of-service/"
              >
                Terms of Service
              </a>
              <br />
              <button className="button" onClick={this.signUp}>
                Sign Up
              </button>
              {this.state.errorMessage !== null &&
              this.state.errorMessage.length > 0 ? (
                <div>
                  <br />
                  <label className="message_label">
                    {this.state.errorMessage}
                  </label>
                </div>
              ) : null}
              <br />
              <label className="or_label">- or -</label>
              <br />
              <button className="method_label" onClick={this.changeSignMethod}>
                Sign In
              </button>
            </div>
          ) : (
            <div className="auth_form">
              <label className="title_label">Sign In</label>
              <br />
              <label className="label">E-mail</label>
              <br />
              <input
                type="email"
                className="input_box"
                value={this.state.email}
                onChange={this.onChangeEmail}
              />
              <br />
              <label className="label">Password</label>
              <input
                type="password"
                className="input_box"
                value={this.state.password}
                onChange={this.onChangePassword}
              />
              <br />
              <a className="method_label2" href="/#/resetPassword">
                Forgot password?
              </a>
              <br />

              <button className="button" onClick={this.signIn}>
                Sign In
              </button>

              {this.state.errorMessage !== null &&
              this.state.errorMessage.length > 0 ? (
                <div>
                  <br />
                  <label className="message_label">
                    {this.state.errorMessage}
                  </label>
                </div>
              ) : null}
              <br />
              <label className="or_label">- or -</label>
              <br />
              <button className="method_label" onClick={this.changeSignMethod}>
                Sign Up
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
