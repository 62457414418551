import axios from "axios";
import CookieHelper from "./CookieHelper";

const cookieValue = CookieHelper.get();

const prodBaseUrl = "https://api.authentication.davidsimak.dev";
// const testBaseUrl = "http://localhost:8040"

export default axios.create({
  baseURL: prodBaseUrl,
  withCredentials: false,
  headers: {
    session: cookieValue,
  },
});
