import React, { Component } from "react";
import methods from "../methods";
import reportVisitService from "../reporting-visits/report-visit.service";
import authenticationService from "../services/authentication.service";

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.sendResetPassword = this.sendResetPassword.bind(this);

    this.state = {
      email: "",
      errorMessage: "",
      successMessage: "",
      isLoading: false,
    };
  }

  componentDidMount() {
    document.title = "Reset passwrod | Authentication";
    reportVisitService.report(window.location.pathname, document.title);
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  sendResetPassword() {
    if (this.state.email.length > 0) {
      if (methods.validateEmail(this.state.email)) {
        if (window.confirm("Do you really want reset password?")) {
          this.setState({
            isLoading: true,
          });
          authenticationService
            .sendResetPassword(this.state.email)
            .then((response) => {
              if (response.data.status === "SUCCESS") {
                this.setState({
                  successMessage: response.data.message,
                  errorMassage: "",
                  isLoading: false,
                });
              } else {
                this.setState({
                  successMessage: "",
                  isLoading: false,
                  errorMessage: response.data.message,
                });
              }
            })
            .catch((e) => {
              methods.processError(e);
            });
        }
      } else {
        this.setState({
          successMessage: "",
          errorMessage: "Your email address is not valid!",
        });
      }
    } else {
      this.setState({
        successMessage: "",
        errorMessage: "You must type your email address!",
      });
    }
  }

  render() {
    return (
      <div>
        {this.state.isLoading ? (
          <div id="backgroundLoader">
            <div id="loader" />
          </div>
        ) : null}

        <div className="container mt-3">
          <div className="auth_form">
            <label className="title_label">Reset password</label>
            <br />
            <label className="label">E-mail</label>
            <br />
            <input
              type="email"
              className="input_box"
              value={this.state.email}
              onChange={this.onChangeEmail}
            />
            <br />

            <button className="button" onClick={this.sendResetPassword}>
              Reset password
            </button>

            {this.state.errorMessage !== null &&
            this.state.errorMessage.length > 0 ? (
              <div>
                <br />
                <label className="message_label">
                  {this.state.errorMessage}
                </label>
              </div>
            ) : (
              <div />
            )}

            {this.state.successMessage !== null &&
            this.state.successMessage.length > 0 ? (
              <div>
                <br />
                <label className="label">{this.state.successMessage}</label>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
    );
  }
}
