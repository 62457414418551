import http from "../http-common";

class AuthenticationService {
  sendResetPassword(email) {
    return http.post(`/reset-password?email=${email}`);
  }

  login(data) {
    return http.post(`/login`, data);
  }

  register(data) {
    return http.post(`/register`, data);
  }
}

export default new AuthenticationService();
