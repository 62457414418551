class Methods {
  processError(error) {
    if (error.response.status === 401) {
      window.location.href = "https://authentication.davidsimak.dev/";
      return;
    }

    if (error.response.status === 403) {
      window.location.href = "https://www.davidsimak.dev/access-denied";
      return;
    }

    if (error.response.status === 404) {
      window.location.href = "https://www.davidsimak.dev/not-found";
      return;
    }

    window.location.href = "https://www.davidsimak.dev/unavailable-service";
  }
  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}

export default new Methods();
